import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { FiberInstallationConfirm } from '../models/fiber-installation-confirmation';
import { FiberInstallationSchedule } from '../models/fiber-installation-schedule';
import { InquiryInformation } from '../models/inquiry-informations';
import { ResolveBEHttpErrorToErrorMessage } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class FiberOrderService {
  constructor(private http: HttpClient) {}
  private scheduleInformations = new BehaviorSubject(null);
  public GetInquiryInformations(
    order_hash: string
  ): Observable<InquiryInformation> {
    const params = new HttpParams().append('order', order_hash);
    return this.http
      .get<InquiryInformation>(Constants.API_INQUIRY_INFORMATIONS, { params })
      .pipe(catchError(this.handleError));
  }
  public GetFiberInstallationOption(
    contract_hash: string
  ): Observable<FiberInstallationSchedule> {
    const params = new HttpParams().append('contract_hash', contract_hash);
    return this.http.get<FiberInstallationSchedule>(
      Constants.API_INSTALLATION_OPTION,
      {
        params,
      }
    );
  }
  public GetFiberInstallationSchedule(
    contract_hash: string
  ): Observable<FiberInstallationSchedule> {
    const params = new HttpParams().append('contract_hash', contract_hash);
    return this.http.get<FiberInstallationSchedule>(
      Constants.API_INSTALLATION_SCHEDULE,
      {
        params,
      }
    );
  }
  public GetFiberInstallationConfirm(
    contract_hash: string
  ): Observable<FiberInstallationConfirm> {
    const params = new HttpParams().append('contract_hash', contract_hash);
    return this.http.get<FiberInstallationConfirm>(
      Constants.API_GET_INSTALLATION_SCHEDULE_CONFIRMATION,
      { params }
    );
  }
  public SaveFiberInstallationSchedule(data: any): Observable<any> {
    // save schedule information
    this.scheduleInformations.next(data.schedule);
    return this.http
      .post(Constants.API_SAVE_INSTALLATION_SCHEDULE, data)
      .pipe(catchError(this.handleError));
  }
  public SaveFiberInstallationContactOption(data: any): Observable<any> {
    // save schedule information
    this.scheduleInformations.next(data.schedule);
    return this.http
      .post(Constants.API_INSTALLATION_OPTION, data)
      .pipe(catchError(this.handleError));
  }
  public SaveFiberInstallationScheduleConfirmations(
    data: any
  ): Observable<any> {
    const schedule_info = this.scheduleInformations.getValue();
    data = { ...data, schedule: schedule_info };
    return this.http
      .post(Constants.API_SAVE_INSTALLATION_SCHEDULE_CONFIRMATION, data)
      .pipe(catchError(this.handleError));
  }

  // tslint:disable-next-line:typedef
  private handleError(err) {
    console.log("FiberOrderService : handleError",err);
    let errorMessage: string;
    let message = ResolveBEHttpErrorToErrorMessage(err);
    errorMessage = `An error occurred: ${message}`;
    // if (err.error instanceof ErrorEvent) {
    //   let message = ResolveBEHttpErrorToErrorMessage(err);
    //   errorMessage = `An error occurred: ${message}`;
    // } else {
    //   errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    // }
    return throwError(errorMessage);
  }

  private isChecked = new BehaviorSubject<boolean>(this.getInitialState());
  isChecked$ = this.isChecked.asObservable();

  setChecked(value: boolean) {
    this.isChecked.next(value);
    localStorage.setItem('unsure_rental_company', value.toString());
  }

  public getInitialState(): boolean {
    const storedValue = localStorage.getItem('user_rental_company');
    console.log(`Loaded state from localStorage: ${storedValue}`);
    return storedValue === 'true';
  }
}
