import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'inquiry-order',
        loadChildren: () =>
          import('./inquiry/inquiry.module').then((m) => m.InquiryModule),
      },
      {
        path: 'fiber-order',
        loadChildren: () =>
          import('./fiber-order/fiber-order.module').then(
            (m) => m.FiberOrderModule
          ),
      },
      {
        path: 'installation-schedule-form',
        loadChildren: () =>
          import('./installation-schedule/installation-schedule.module').then(
            (m) => m.InstallationScheduleModule
          ),
      },
      {
        path: 'installation-option-form',
        loadChildren: () =>
          import('./installation-schedule/installation-contact-option.module').then(
            (m) => m.InstallationContactOptionModule
          ),
      },
      { path: '', redirectTo: 'inquiry-order', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
